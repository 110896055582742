import { Box, Skeleton, Theme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { RoleGuard } from "guards";
import { PrivateRoutes, Roles } from "models";
import { GlobalProvider } from "pages/locations";
import LocationDashboardViewWithCall from "pages/locations/components/organism/locationDashboardView/LocationDashboardViewWithCall";
import { OrganizationCard } from "pages/organizations/components/molecules/OrganizationCard";
import { useOrganizationContext } from "pages/organizations/context";

import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppStore } from "redux/store";

import UserDashboardView from "pages/users/components/organism/UserDashboardView";

import { GoBackBreadcrumb } from "components";
import { useRoleGuard } from "hooks";
import { useTranslation } from "react-i18next";
import { useOrganizationInfoContext } from "../../context/OrganizationInfoContext";
import useOrganization from "../../hooks/useOrganization";

export default function OrganizationDashboard() {
  //**Context */
  const { logoText, name, url, cityOrganization } = useOrganizationContext();
  const { active, setActive } = useOrganizationInfoContext();

  //**Hooks */
  const params = useParams();
  const navigate = useNavigate();
  const adminRoles = [Roles.superAdmin, Roles.multiOrgOwner, Roles.generalViewOnly];
  const isAdmin = useRoleGuard(adminRoles);
  const { t } = useTranslation();
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { setToggleGetData } = useOrganizationInfoContext();

  //** Redux */
  const userState = useSelector((store: AppStore) => store.user);
  const { organization } = userState;

  const orgId = params.id || `${organization?.id}`;

  //** Data get information */
  const { loading } = useOrganization(orgId);
  const handleUpdateStatus = () => setActive((prev) => !prev);

  return (
    <GlobalProvider>
      <Box mb={underMd ? 32 : 0}>
        <RoleGuard authorizedRoles={adminRoles}>
          <GoBackBreadcrumb
            label={t("GO-BACK-ORGANIZATION-DASHBOARD")}
            onClick={() => {
              navigate(`/${PrivateRoutes.ORGANIZATIONS}/${PrivateRoutes.LIST}`);
            }}
          />
        </RoleGuard>

        <Grid container spacing={12} mx={0} mt={isAdmin ? 0 : 12}>
          <Grid xs={12} md={4} lg={3}>
            {loading || !name ? (
              <Skeleton height="280px" variant="rounded" />
            ) : (
              <OrganizationCard
                id={orgId}
                onSearch={() => {
                  setToggleGetData((prev) => !prev);
                }}
                logo={logoText}
                name={name}
                url={url}
                address={cityOrganization}
                active={active}
                handleClickCard={() => {}}
                isDashboard
                handleUpdateStatus={handleUpdateStatus}
              />
            )}
            <UserDashboardView />
          </Grid>
          <Grid xs={12} md={8} lg={9} height="100%">
            <LocationDashboardViewWithCall isDashboard />
          </Grid>
        </Grid>
      </Box>
    </GlobalProvider>
  );
}
