import { useEffect } from "react";
import { Box, Skeleton, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "context/globalContext";
import { UsersList } from "pages/users";
import { Maps } from "components";
import { useLocationContext } from "pages/locations/context";
import { LocHeaderLanding } from "../../molecules";
import { LocAddressInfo } from "../../molecules/LocAddressInfo";
import { LocOpenHoursLanding } from "../../molecules/LocOpenHoursLanding";
import { BusinessLocLanding } from "../../molecules/BusinessLocLanding";
import { LocOwnerLanding } from "../../molecules/LocOwnerLanding";
import SBDConnectLanding from "../../molecules/SBDConnectLanding/SBDConnectLanding";

export default function LocationLanding({ loading }: { loading?: boolean }) {
  const { id } = useParams();
  const { setLocEditMode } = useGlobalContext();
  const { latitude, longitude, name, isVisible, address1 } = useLocationContext();

  const under1300px = useMediaQuery("(max-width: 1325px)");
  const underMediumHeight = useMediaQuery("(max-height: 800px)");

  useEffect(() => {
    setLocEditMode(false);
  }, []);
  return (
    <Box>
      <LocHeaderLanding loading={loading} />
      <Box display="flex" mt={4}>
        <Box width="70%" pr={4}>
          <LocAddressInfo loading={loading} />
          <Box mt={2} mb={4}>
            <LocOpenHoursLanding loading={loading} />
          </Box>
          <Box display="flex">
            <Box width="50%" mt={4}>
              <Maps
                positions={
                  [
                    {
                      lat: +latitude || 180,
                      lng: +longitude || 0,
                      name,
                      status: isVisible,
                      address: address1,
                    },
                  ] || []
                }
                singleDetail
                mapHeight={underMediumHeight ? "440px" : "calc(100vh - 420px)"}
              />
            </Box>
            <Box
              width="50%"
              sx={{ backgroundColor: "background.paper" }}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              mt={4}
            >
              <Box height="calc(100vh - 600px)" maxHeight="calc(100vh - 470px)" minHeight="300px" overflow="scroll">
                <BusinessLocLanding loading={loading} />
              </Box>
              {loading ? <Skeleton width="100%" height={100} variant="rounded" sx={{ mt: 4 }} /> : <SBDConnectLanding />}
            </Box>
          </Box>
        </Box>
        <Box width="30%" sx={{ backgroundColor: "background.paper" }}>
          <Box height={under1300px ? "170px" : "148px"}>
            <LocOwnerLanding loading={loading} />
          </Box>
          <Box>
            <UsersList locationId={id || ""} embebed staticTop locationLanding />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
