/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTables } from "hooks";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Chip } from "components";
import { PrivateRoutes } from "models";
import useUserTableItems from "pages/users/hooks/useUserTableItems";
import { IUsersListDOM } from "pages/users/models";
import { useUsersListContext } from "pages/users/pages/usersList/context";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getColorChipByRole } from "utilities/getColorChips";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { UserFastActions } from "../../molecules/UserFastActions";

export default function TableResponsiveRow({ data, onSearch }: { data: IUsersListDOM; onSearch?: () => void }) {
  //**Vars */

  const { usersArray, selected } = useUsersListContext();

  //**Hooks */
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setSelected } = useUsersListContext();
  const { handleClickTableCheckbox, isSelected, handleClickTableCheckboxMobile } = useTables(setSelected, selected, usersArray);
  const { handleTouchStart, handleTouchMove, handleTouchEnd, hoverItem, setHoverItem } = useUserTableItems({
    handleClickTableCheckboxMobile,
  });
  //** Media querys */
  const underMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const smAbove = useMediaQuery("(min-width:900px)");
  const over1000px = useMediaQuery("(min-width:1000px)");

  const isItemSelected = isSelected(data.id);
  const isItemHover = data.id === hoverItem;

  //Table logic to navigate
  const handleNavigate = (id: string) => {
    navigate(`/${PrivateRoutes.USERS}/${PrivateRoutes.INFORMATION_NAV}/${id}`, { replace: false });
  };
  const theme = useTheme();

  function stringAvatarOrgList(name: string, lastName: string, isActivate: boolean) {
    const successColor = theme.palette.customColors.successBackground;
    const defaultColor = theme.palette.customColors.secondaryBackground;

    return {
      sx: {
        bgcolor: isActivate ? successColor : defaultColor,
        color: isActivate ? theme.palette.common.black : theme.palette.common.white,
        fontWeight: 600,
        width: 50,
        height: 50,
      },
      children: `${name?.split(" ")[0][0]?.toUpperCase() || ""}${lastName?.split(" ")[0][0]?.toUpperCase() || ""}`,
    };
  }

  return (
    <ListItem sx={{ px: 4, py: 0 }}>
      <ListItemButton
        role={undefined}
        onClick={() => {
          handleNavigate(data.id);
        }}
        sx={{
          pl: 6,
          pr: 4,
          backgroundColor: "background.paper",
          borderBottom: (theme: Theme) => `${theme.palette.divider} 1px solid`,

          display: "flex",
          alignItems: "center",
          height: !underMd ? "100px" : "auto",
          py: underMd ? 4 : 0,
        }}
        onMouseEnter={smAbove ? () => setHoverItem(data.id) : undefined}
        onMouseLeave={smAbove ? () => setHoverItem("") : undefined}
        onTouchStart={smAbove ? undefined : () => handleTouchStart(data.id, !data.isSuspended)}
        onTouchEnd={smAbove ? undefined : () => handleTouchEnd()}
        onTouchMove={smAbove ? undefined : () => handleTouchMove()}
      >
        <ListItemIcon onClick={(e) => e.stopPropagation()} sx={{ mr: 0 }}>
          <Checkbox
            onClick={(event) => handleClickTableCheckbox(event, data.id, !data.isSuspended)}
            sx={
              isItemHover || isItemSelected || selected.length > 0
                ? { "& .MuiSvgIcon-root": { fontSize: 24 }, visibility: "visible", display: "block", p: 0, pl: 4 }
                : { visibility: "hidden", display: "none" }
            }
            checked={isItemSelected}
          />
        </ListItemIcon>
        <ListItemAvatar sx={{ mr: 8 }}>
          <Box display="flex">
            <Box display="flex" onClick={(e) => e.stopPropagation()} pl={8}>
              <Avatar {...stringAvatarOrgList(`${data.firstName}`, `${data.lastName}`, !data.isSuspended)} />
            </Box>
          </Box>
        </ListItemAvatar>
        <Box width="100%" pr={7.2} display="flex" justifyContent="space-between">
          <Box width="100%" display="flex" alignItems="center">
            <Box display="flex" alignItems="flex-start" flexDirection="column" width={over1000px ? "30%" : "100%"}>
              <Typography
                variant="h3"
                fontWeight="700"
                mb={2}
                mt={2}
                sx={{ display: "flex", justifyContent: "space-between" }}
                alignItems="center"
              >
                {`${data.firstName} ${data.lastName}`.toUpperCase()}
              </Typography>

              <Box display="flex" alignItems="center" flexWrap="wrap" gap={2} mt={2}>
                <Chip
                  label={data.roleData[0].name || ""}
                  size="small"
                  fontSize={underMd ? 10 : 12}
                  customColors={getColorChipByRole(data.roleData[0].name)}
                />
              </Box>
            </Box>
            {over1000px && (
              <Box width="30%" display="flex" alignItems="center">
                <IconButton
                  onClick={(e: any) => {
                    e.stopPropagation();
                    window.location.href = `tel:${data.phone}`;
                  }}
                  sx={{ color: (theme: Theme) => theme.palette.text.primary, py: 2, px: 2 }}
                >
                  <CallOutlinedIcon sx={{ fontSize: 22, pb: 0 }} />
                </IconButton>
                <Typography variant="h4" color="text.secondary">
                  {data.phone}
                </Typography>
              </Box>
            )}
            {over1000px && (
              <Box width="30%" display="flex" alignItems="center">
                <IconButton
                  onClick={(e: any) => {
                    e.stopPropagation();
                    window.location.href = `mailto:${data.username}`;
                  }}
                  sx={{ color: (theme: Theme) => theme.palette.text.primary, py: 2, px: 2 }}
                >
                  <EmailOutlinedIcon sx={{ fontSize: 22 }} />
                </IconButton>
                <Typography variant="h4" color="text.secondary">
                  {data.username}
                </Typography>
              </Box>
            )}
          </Box>

          <Box display="flex" alignItems="center" width={underMd ? 50 : 140} justifyContent="flex-end">
            <Box
              sx={{
                display: "flex",
                alignItems: underMd ? "center" : "flex-end",
                justifyContent: "flex-start",
              }}
              gap={2}
            >
              {underMd ? (
                <>
                  {data.isSuspended ? (
                    <Box
                      bgcolor={(theme: Theme) => {
                        return theme.palette.mode === "dark" ? theme.palette.secondary.dark : theme.palette.secondary.light;
                      }}
                      borderRadius="50%"
                      width="12px"
                      height="12px"
                    />
                  ) : (
                    <Box
                      bgcolor={(theme: Theme) => (theme.palette.mode === "dark" ? theme.palette.success.dark : theme.palette.success.light)}
                      borderRadius="50%"
                      width="12px"
                      height="12px"
                    />
                  )}
                </>
              ) : (
                <>
                  {data.isSuspended ? (
                    <Chip size="small" label={t("OPTION-SELECT-1")} color="secondary" sx={{ fontSize: "1.4rem" }} />
                  ) : (
                    <Chip size="small" label={t("OPTION-SELECT-2")} color="success" sx={{ fontSize: "1.4rem" }} />
                  )}
                </>
              )}
              <UserFastActions
                id={data.id}
                isActive={!data.isSuspended}
                handleMouseLeave={() => setHoverItem("")}
                onSearch={() => {
                  if (onSearch) onSearch();
                }}
              />
            </Box>
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
